<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToPage('/bigscreen/menuPage')">巴林右旗大坂镇{{ hamletName }}防疫统计</div> 
    </div>

    <div class="content_box">
      <!-- 地图 -->
      <div class="villages_box">
        <da-ban-map @changeHamletName="changeHamletName" />
      </div> 

      <!-- 图表 -->
      <div class="content_item_box">
        <div class="w_full h_30_p chart_box">
          <div class="title"><span>疫苗用量</span></div>
          <bing-tu class="chart" :value="data2" />
        </div>

        <div class="w_full h_30_p chart_box">
          <div class="title"><span>牲畜防疫统计</span></div>

          <div class="grow_box" ref="tabName">
            <div 
              class="grow_name cursor" 
              :class="{ activeColor: activeIndex2 == index }"
              v-for="(item, index) in cattleList" :key="index" 
              @click="changeChart2(index)"
              @mousewheel="wheelScroll"
            >
              {{ item.name }}
            </div>
          </div>

          <ban-bing-tu class="chart_2" :value="data1" />
        </div>

        <div class="w_full h_30_p chart_box">
          <div class="title"><span>发病统计</span></div>

          <div class="grow_box" ref="tabName">
            <div 
              class="grow_name cursor" 
              :class="{ activeColor: activeIndex3 == index }"
              v-for="(item, index) in jiBingList" :key="index" 
              @click="changeChart3(index)"
              @mousewheel="wheelScroll"
            >
              {{ item.name }}
            </div>
          </div>

          <zhu-zhuang-tu  class="chart_3" :value="data3" /> 
        </div>

      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'
  import BanBingTu from '../charts/ban-bing-tu.vue';


  import DaBanMap from '../maps/da-ban-hamlet/all-hamlet.vue'
  import SuBoRiGa from '../maps/suBoRiGa.vue'
  import XingFuZhiLu from '../maps/xingFuZhiLu.vue'
  import BaYanHuShuoHamlet from '../maps/ba-yan-hu-shuo-hamlet.vue'
  import ChaGanMuLunHamlet from '../maps/cha-gan-mu-lun-hamlet.vue'
  import MeiDianHua from '../maps/mei-dian-hua.vue'
  import BaYanTaLa from '../maps/ba-yan-ta-la.vue'
  import BaoRiWuSu from '../maps/bao-ri-wu-su.vue'
  import XiLaMuLun from '../maps/xi-la-mu-lun.vue'
  import ChaGanNuoEr from '../maps/cha-gan-nuo-er.vue'
  import kongXinBingTu from '../charts/kong-xin-bing-tu.vue'
  import ZhuZhuangTu from '../charts/zhu-zhuang-tu.vue';

  export default {
    name: 'HamletFangYiTongJi',
    components: {
    BaLinYouQiMap,
    BingTu,
    BanBingTu,
    DaBanMap,
    SuBoRiGa,
    XingFuZhiLu,
    BaYanHuShuoHamlet,
    ChaGanMuLunHamlet,
    MeiDianHua,
    BaYanTaLa,
    BaoRiWuSu,
    XiLaMuLun,
    ChaGanNuoEr,
    kongXinBingTu,
    ZhuZhuangTu
  },
    data() {
      return {
        activeIndex1: 0,
        activeIndex2: 0,
        activeIndex3: 0,
        mapName: '巴林右旗大板镇',
        hamletName: '',

        data1: {},
        data2: {},
        data3: {},

        cattleList: [
          { name: '牛' },
          { name: '马' },
          { name: '山羊' },
          { name: '绵羊' },
          { name: '毛驴' },
          { name: '骆驼' }
        ],

        jiBingList: [
          { name: '擒流感' },
          { name: '口蹄疫' },
          { name: '疯牛疫' },
          { name: '牛结核病' },
          { name: '布病' },
          { name: '其他' }
        ],

        hamletList: [
          { name: '红旗嘎查', value: 3289 },
          { name: '吉布吐嘎查', value: 2578 },
          { name: '西哈嘎查', value: 1956 },
          { name: '十家子嘎查', value: 1664 },
          { name: '查干诺尔嘎查', value: 1239 },
          { name: '浩饶沁嘎查', value: 1217 },
          { name: '大冷嘎查', value: 1189 },
          { name: '古力古台嘎查', value: 1078 },
          { name: '苏艾力嘎查', value: 819 },
          { name: '太布呆嘎查', value: 784 },
          { name: '昭胡都格嘎查', value: 526 },
          { name: '新达冷村', value: 517 },
          { name: '苏艾里嘎查', value: 489 },
          { name: '西拉汰沦厚查', value: 475 },
          { name: '巴彦汉嘎查', value: 379 },
          { name: '温根吐嘎查', value: 334 },
          { name: '翁根图毛都嘎查', value: 321 },
          { name: '红星村', value: 267 },
          { name: '莫日古其格', value: 229 },
          { name: '独希嘎查', value: 219 },
          { name: '新立村', value: 207 },
          { name: '友爱村', value: 198 },
          { name: '益斯毛都嘎查', value: 169 },
          { name: '准宝日嘎查', value: 157 },
          { name: '巴罕村', value: 142 },
          { name: '套白村', value: 115 }
        ],
      }
    },
    mounted() {
      this.hamletName = this.$route.params.val
      
      setTimeout(() => {
        this.initChart()
      }, 100)

    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          unit: '头',
          data: [
            {"name": "牛", "value": 18548, "unit": ''},
            {"name": "马", "value": 9832, "unit": ''},
            {"name": "绵羊", "value": 7821, "unit": ''},
            {"name": "山羊", "value": 9676, "unit": ''},
            {"name": "驴", "value": 243, "unit": ''},
            {"name": "骆驼", "value": 3451, "unit": ''}
          ]
        }

        this.data2 = {
          unit: '支',
          data: [
            {"name": "疫苗A", "value": 23570, "unit": ''},
            {"name": "疫苗B", "value": 12432, "unit": ''},
            {"name": "疫苗C", "value": 12521, "unit": ''},
            {"name": "疫苗D", "value": 13676, "unit": ''},
            {"name": "疫苗E", "value": 2343, "unit": ''},
            {"name": "疫苗F", "value": 3451, "unit": ''}
          ]
        }

        this.data3 = {
          xRotate: true,
          yName: '吨',
          showYNum: false,
          x: ['2019', '2020', '2021', '2022'],
          y: [3245, 7827, 2345, 9883]
        }
      },

      // 跳转页面
      goToPage(val) {
        this.$router.push({
          path: val
        })
      },

      // 修改 嘎查村名称
      changeHamletName(val) {
        console.log('111', val);
        this.hamletName = val
      },

      // 改变 图表2 数据
      changeChart2(val) {
        this.activeIndex2 = val

        if (val == 0) {
          this.data1 = {
            unit: '支',
            data: [
              {"name": "疫苗A", "value": 23570, "unit": ''},
              {"name": "疫苗B", "value": 12432, "unit": ''},
              {"name": "疫苗C", "value": 12521, "unit": ''},
              {"name": "疫苗D", "value": 13676, "unit": ''},
              {"name": "疫苗E", "value": 2343, "unit": ''},
              {"name": "疫苗F", "value": 3451, "unit": ''}
            ]
          }
        } else if (val == 1) {
          this.data1 = {
            unit: '支',
            data: [
              {"name": "疫苗A", "value": 3570, "unit": ''},
              {"name": "疫苗B", "value": 2432, "unit": ''},
              {"name": "疫苗C", "value": 2521, "unit": ''},
              {"name": "疫苗D", "value": 3676, "unit": ''},
              {"name": "疫苗E", "value": 2343, "unit": ''},
              {"name": "疫苗F", "value": 3451, "unit": ''}
            ]
          }
        } else if (val == 2) {
          this.data1 = {
            unit: '支',
            data: [
              {"name": "疫苗A", "value": 7266, "unit": ''},
              {"name": "疫苗B", "value": 4432, "unit": ''},
              {"name": "疫苗C", "value": 3521, "unit": ''},
              {"name": "疫苗D", "value": 1676, "unit": ''},
              {"name": "疫苗E", "value": 1343, "unit": ''},
              {"name": "疫苗F", "value": 3451, "unit": ''}
            ]
          }
        } else if (val == 3) {
          this.data1 = {
            unit: '支',
            data: [
              {"name": "疫苗A", "value": 266, "unit": ''},
              {"name": "疫苗B", "value": 432, "unit": ''},
              {"name": "疫苗C", "value": 521, "unit": ''},
              {"name": "疫苗D", "value": 676, "unit": ''},
              {"name": "疫苗E", "value": 343, "unit": ''},
              {"name": "疫苗F", "value": 451, "unit": ''}
            ]
          }
        } else if (val == 4) {
          this.data1 = {
            unit: '支',
            data: [
              {"name": "疫苗A", "value": 1266, "unit": ''},
              {"name": "疫苗B", "value": 2432, "unit": ''},
              {"name": "疫苗C", "value": 1521, "unit": ''},
              {"name": "疫苗D", "value": 976, "unit": ''},
              {"name": "疫苗E", "value": 343, "unit": ''},
              {"name": "疫苗F", "value": 451, "unit": ''}
            ]
          }
        } else if (val == 5) {
          this.data1 = {
            unit: '支',
            data: [
              {"name": "疫苗A", "value": 2266, "unit": ''},
              {"name": "疫苗B", "value": 1432, "unit": ''},
              {"name": "疫苗C", "value": 521, "unit": ''},
              {"name": "疫苗D", "value": 976, "unit": ''},
              {"name": "疫苗E", "value": 343, "unit": ''},
              {"name": "疫苗F", "value": 451, "unit": ''}
            ]
          }
        }
      }, 

      // 改变 图表3 数据
      changeChart3(val) {
        this.activeIndex3 = val

        if (val == 0) {
          this.data3 = {
            xRotate: true,
            yName: '吨',
            showYNum: false,
            x: ['2019', '2020', '2021', '2022'],
            y: [3245, 7827, 2345, 9883]
          }
        } else if (val == 1) {
          this.data3 = {
            xRotate: true,
            yName: '吨',
            showYNum: false,
            x: ['2019', '2020', '2021', '2022'],
            y: [4748, 3784, 1945, 7883]
          }
        } else if (val == 2) {
          this.data3 = {
            xRotate: true,
            yName: '吨',
            showYNum: false,
            x: ['2019', '2020', '2021', '2022'],
            y: [2245, 5827, 1845, 5683]
          }
        } else if (val == 3) {
          this.data3 = {
            xRotate: true,
            yName: '吨',
            showYNum: false,
            x: ['2019', '2020', '2021', '2022'],
            y: [2245, 3827, 1745, 1093]
          }
        } 
      },

      wheelScroll(e) {
        let box = this.$refs.tabName
        e.preventDefault()
        box.scrollLeft += e.deltaY
      }
    }
  }
</script>

<style scoped lang="less">
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor { color: #2366fc !important; }

  .box_1 { 
    background-image: url('../../../../assets/images/ba-lin-you-qi/bgc1.png');
    background-size: 100% 100%;
  }
  .header { 
    background-image: url('../../../../assets/images/ba-lin-you-qi/nav-bgc.png');
    background-size: 100% 100%;
  }
  .villages_box { 
    width: 75%;
    height: 100%;
    // background-image: url('../../../../assets/images/ba-lin-you-qi/img4.png');
    background-size: 100% 100%;
  }
  .chart_box {
    width: 100%;
    height: 33.3%;
    background-image: url('../../../../assets/images/ba-lin-you-qi/img5.png');
    background-size: 100% 100%;
    margin-bottom: 10px;

    .title { 
      width: 100%; 
      height: 11%; 
      position: relative;
      // border: 1px solid #f00;

      span {
        font-size: 18px; 
        font-weight: bold; 
        color: #fff; 
        cursor: pointer; 
        position: absolute;
        bottom: 0px;
        right: 20px;
      }
    }

    .chart { height: 89%; }
    .chart_2 { height: 78%; } 
    .chart_3 { height: 80%; }
  }

  .grow_box {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    text-align: center;
    padding: 5px;

    .grow_name { 
      margin-right: 10px;
      white-space: nowrap;
      display: inline-block;
      color: rgb(13, 246, 94);
    }
  }
  .grow_box::-webkit-scrollbar {
    width: 0px;
    height: 100%;
    background-color: #f5f5f5;
  }
  
  .grow_box::-webkit-scrollbar-track {                 // 表示横向向滚动块
    border-radius: 10px;
    background: #2366fc;
  }


  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .title { 
      span { font-size: 12px !important; }
    }
  }
</style>