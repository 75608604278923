import { render, staticRenderFns } from "./hamletFangYiTongJi.vue?vue&type=template&id=edc76ba0&scoped=true&"
import script from "./hamletFangYiTongJi.vue?vue&type=script&lang=js&"
export * from "./hamletFangYiTongJi.vue?vue&type=script&lang=js&"
import style0 from "./hamletFangYiTongJi.vue?vue&type=style&index=0&id=edc76ba0&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edc76ba0",
  null
  
)

export default component.exports